<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Pending Themes
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row v-if="getPendingThemes.length == 0" class="subtitle-1">
                There are currently no new themes to review.
            </v-row>
            <v-row no-gutters v-else>
                <v-col cols="12" class="text-center">Click any theme to review its contents.</v-col>
                <v-col cols="12" v-for="theme in getPendingThemes" :key="theme.id" class="entries pa-3" @click="reviewTheme(theme.id)" style="cursor: pointer">
                    <div class="subtitle-1">{{theme.name}}</div>
                    {{theme.description}}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    methods: {
        reviewTheme(id){
            this.$router.push(`/theme/${id}`)
        }
    },
    computed: {
        getPendingThemes(){
            return this.$store.getters.getThemes.filter(theme => theme.approved == 'pending')
        }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>